@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: 2 1C 2 1D 2 18 2 19;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body,
figure {
  margin: 0;
}
legend,
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* {
  box-sizing: border-box;
}
html {
  height: 100%;
  background: radial-gradient(ellipse at center, #a7cfdf 0%, #23538a 100%);
  background-image: url("https://picsum.photos/1920/1080");
  background-size: cover;
}
html body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  height: 100%;
}
#main {
  height: 100%;
}
#main .rv-reminders {
  height: 100%;
}
#main .rv-reminders .floatingTopNav {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-right: 10px;
  background: linear-gradient(15deg, transparent 0%, transparent 59%, rgba(0,0,0,0.44) 100%);
  width: 100%;
  text-align: right;
  height: 300px;
}
#main .rv-reminders .floatingTopNav button {
  background: transparent;
  border: none;
  color: #fff;
  outline: none !important;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  opacity: 0.8;
  padding: 0;
  margin-left: 20px;
}
#main .rv-reminders .floatingTopNav button:hover {
  opacity: 1;
}
#main .rv-reminders .floatingTopNav span.red-text {
  color: #f00;
  text-transform: uppercase;
  vertical-align: middle;
}
#main .rv-reminders .reminders-slider {
  max-width: 1000px;
  width: 98%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  max-height: 80%;
  overflow: auto;
}
@media (max-width: 1025px) {
  #main .rv-reminders .reminders-slider {
    width: 88%;
  }
}
@media (max-width: 767px) {
  #main .rv-reminders .reminders-slider {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
#main .rv-reminders .reminders-slider .reminder {
  background: rgba(17,17,17,0.8);
  border-radius: 5px;
  padding: 40px 40px 30px 40px;
  margin: 20px 50px;
  color: #fff;
  font-size: 3rem;
  position: relative;
  border: 5px solid transparent;
}
@media (max-width: 767px) {
  #main .rv-reminders .reminders-slider .reminder {
    font-size: 1.2rem;
    padding: 20px;
    margin: 0 20px;
  }
}
@media (min-width: 1440px) {
  #main .rv-reminders .reminders-slider .reminder {
    font-size: 3rem;
  }
}
#main .rv-reminders .reminders-slider .reminder.priority-high {
  background: rgba(255,0,0,0.8);
  border-color: #c00;
}
#main .rv-reminders .reminders-slider .reminder .dateAdded {
  font-size: 0.8rem;
  display: block;
  opacity: 0.6;
  position: absolute;
  top: 12px;
}
@media (max-width: 767px) {
  #main .rv-reminders .reminders-slider .reminder .dateAdded {
    position: relative;
    margin: 20px 0;
  }
}
#main .rv-reminders .reminders-slider .reminder .category {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 1rem;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  text-transform: uppercase;
  opacity: 0.9;
  width: 100px;
  text-align: center;
}
@media (max-width: 767px) {
  #main .rv-reminders .reminders-slider .reminder .category {
    width: 100%;
    right: 0;
  }
}
#main .rv-reminders .reminders-slider .reminder .category.category-general {
  background: #c23616;
}
#main .rv-reminders .reminders-slider .reminder .category.category-raymond {
  background: #4cd137;
}
#main .rv-reminders .reminders-slider .reminder .category.category-vincenzo {
  background: #e1b12c;
}
#main .rv-reminders .reminders-slider .reminder .category.category-zack {
  background: #00a8ff;
}
#main .rv-reminders .reminders-slider .reminder .ready-status {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 0.7rem;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 0;
  text-align: center;
  outline: none !important;
  border: 1px solid #fff;
  box-shadow: none !important;
  opacity: 0.6;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
@media (max-width: 767px) {
  #main .rv-reminders .reminders-slider .reminder .ready-status {
    position: relative;
    display: block;
    margin: 30px auto 0 auto;
  }
}
#main .rv-reminders .reminders-slider .reminder .ready-status.ready-yes {
  background: #27ae60;
}
#main .rv-reminders .reminders-slider .reminder .ready-status.ready-no {
  background: #c0392b;
}
#main .rv-reminders .reminders-slider .reminder .ready-status:hover {
  opacity: 1;
}
#main .rv-reminders .reminders-slider .end-of-list {
  text-align: center;
}
#main .rv-reminders .reminders-slider .end-of-list span {
  display: inline-block;
  background: rgba(0,0,0,0.7);
  color: #fff;
  padding: 20px;
  border-radius: 5px;
}
#main .rv-reminders .category-filter {
  position: absolute;
  background: rgba(17,17,17,0.8);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#main .rv-reminders .category-filter select {
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: transparent;
  color: #fff;
  text-align: center;
  outline: none !important;
  padding: 10px 20px;
}
#main .rv-reminders .category-filter select option {
  color: #000;
}
#main .rv-reminders .inventory {
  background: rgba(255,255,255,0.9);
  position: absolute;
  height: 100%;
  right: 0;
  z-index: 99;
  padding: 10px 20px 20px 20px;
  overflow: auto;
  width: 600px;
  max-width: 100%;
}
#main .rv-reminders .inventory h4 {
  margin-top: 50px;
}
#main .rv-reminders .inventory .btn-close {
  background: #000;
  opacity: 0.8;
  border: none;
  color: #fff;
  border-radius: 50%;
  font-size: 1rem;
  outline: none !important;
  width: 30px;
  padding: 0;
  height: 30px;
  line-height: 28px;
  float: right;
  font-weight: bold;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
#main .rv-reminders .inventory .btn-close:hover {
  opacity: 1;
}
#main .rv-reminders .inventory .scrollSpeed button {
  width: 49%;
  margin-right: 2%;
  background: #000;
  color: #fff;
  box-shadow: none;
  border: 1px solid #000;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  padding: 8px;
}
#main .rv-reminders .inventory .scrollSpeed button:hover {
  opacity: 0.8;
}
#main .rv-reminders .inventory .scrollSpeed button:last-of-type {
  margin-right: 0;
}
#main .rv-reminders .inventory .reminder-form {
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 11px 5px rgba(0,0,0,0.2);
}
#main .rv-reminders .inventory .reminder-form textarea {
  border-radius: 5px;
  width: 100%;
  min-height: 50px;
  margin-bottom: 10px;
  padding: 5px;
  font-weight: bold;
  font-size: 1.2rem;
}
#main .rv-reminders .inventory .reminder-form select {
  border-radius: 5px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 49%;
  margin-right: 2%;
  padding: 5px;
  background: #fff;
}
#main .rv-reminders .inventory .reminder-form select:last-of-type {
  margin-right: 0;
}
#main .rv-reminders .inventory .reminder-form .buttonHolder {
  text-align: right;
}
#main .rv-reminders .inventory .reminder-form .buttonHolder button {
  margin-top: 6px;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 0.8rem;
  color: #c00;
  outline: none !important;
}
#main .rv-reminders .inventory .reminder-form .readyLabel {
  color: #fff;
  background: #27ae60;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: default;
}
#main .rv-reminders .inventory .reminder-form.reminder-form-add {
  margin-bottom: 30px;
  background: rgba(0,0,0,0.7);
  padding: 20px;
}
#main .rv-reminders .inventory .reminder-form.reminder-form-add textarea,
#main .rv-reminders .inventory .reminder-form.reminder-form-add select {
  padding: 10px;
}
#main .rv-reminders .inventory .reminder-form.reminder-form-add button {
  width: 100%;
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 5px;
  background: transparent;
  color: #fff;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
#main .rv-reminders .inventory .reminder-form.reminder-form-add button:hover {
  background: rgba(0,0,0,0.7);
}
#main .rv-reminders .inventory .sample-load {
  display: none;
}
.slick-prev:before,
.slick-next:before {
  font-size: 2rem !important;
}
.privacy-link {
  color: #fff;
  text-decoration: none;
  font-weight: normal;
}

